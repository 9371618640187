@import '../../global/styles/lib';

$left-col-margin: 0.5rem;
$right-col-margin: $left-col-margin * 3;
$footer-font-size: 1.25rem;
$footer-font-size-small: $footer-font-size - 0.25rem;

$sorta-small: 466px;

// Keep footer at bottom no matter content length
html,
body {
	height: 100%;
	position: relative;
}

#container {
	min-height: 100vh;
	position: relative;
	display: block;
	overflow: hidden;
} // #container

#theme-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: auto;
	overflow: hidden;
	z-index: 0;

	padding-left: 1rem;
	padding-right: 1rem;
	background: $black;
	clear: both;
	padding: ($block-padding / 2) 1rem 0 1rem;
	font-size: $footer-font-size;

	.banner-container {
		@include absolute-full;
		overflow: hidden;	
		&::before {
			content: "";
			@include absolute-full;
			opacity: 0.85;
			z-index: 1;
		} // &::before

		.banner {
			@include absolute-full;
			filter: grayscale(1);
		} // .banner

	} // .banner-container
	
	.container {
		display: flex;
		flex-wrap: wrap-reverse;
		align-items: center;
		
		&__left {
			width: 100%;
			text-align: center;
			&__copy {
				color: $white;
				p {
					margin: 0;
					font-size: 1rem;
				} // p
			} // &__copy

		} // &__left
		
		&__right {
			width: 100%;
			text-align: center;
			.button {
				color: $white;
				margin-bottom: 2rem;
				padding: 0.65rem 1.5rem;
			}
			.social-icons {
				display: flex;
				justify-content: center;
				margin-bottom: 1rem;
				max-height: 2rem;
				.icon {
					&:not(:last-child) {
						margin-right: 2rem;
					} // &:not(:last-child)
					
					width: 1.5rem;
					height: auto;					
					svg {
						fill: none;
						stroke: $white;
						transition: stroke $hover-transition;
						&:hover {
							stroke: $red;
						} // &:hover
					} // svg
				} // .icon
				
			} // .social-icons
		} // &__right
		
		/*
		@include breakpoint(medium up) {
			flex-wrap: wrap;
			&__left {
				width: 50%;
				text-align: left;
			} // &__left

			&__right {
				width: 50%;
				.social-icons {
					justify-content: flex-end;
					margin-bottom: 0;
				} // .social-icons
			} // &__right
		} // medium up
		*/
		
		
	} // .container
		
} // #theme-footer